const buttons = {
  'Recovery button': 'Восстановить',
  'Change button': 'Изменить',
  'Back button': 'Назад',
  'Create button': 'Создать',
  'Save button': 'Сохранить',
  'Update button': 'Обновить',
  'Delete button': 'Удалить',
  'Search button': 'Найти',
  'Clear button': 'Очистить',
  'Copy button': 'Скопировать',
  'Apply button': 'Применить',
  'Cancel button': 'Отмена',
  'Ok button': 'Ok',
  'Back to Home': 'Вернуться на главную',
  'status-city': 'Инфо',
  'cancel-city': 'Отмена',
  'register-city': 'Зарегистрировать',
  'Create transaction': 'Создать транзакцию',
  'Create transaction confirmation': 'Создать справку о транзакциях',
  Create: 'Создать'
};

const menu = {
  'Transaction menu': 'Транзакции',
  'Users menu': 'Пользователи',
  'Users inactive menu': 'Не активные',
  'Users active menu': 'Активные',
  'Role menu': 'Роли',
  'User Item': 'Информация о пользователе',
  'Banks menu': 'Банки',
  'Flow menu': 'Схемы',
  'Gateway menu': 'Шлюз',
  'Cascading menu': 'Каскад',
  'Cascading rules menu': 'Правила',
  'Cascading models menu': 'Модели',
  'Terminals menu': 'Терминалы',
  'Merchant menu': 'Мерчанты',
  'Description menu': 'Экспорт документов',
  'Description menu custom': 'Экспорт',
  'Codes menu': 'Коды',
  'Bin menu': 'Bin',
  'Reconciliation menu': 'Сверка',
  'Catalogs menu': 'Справочники',
  'Manual giveout menu': 'Ручные выдачи',
  'Manual giveout list': 'Список ручных выдач',
  'Sanctions List': 'Санкционные списки',
  'Financial monitoring': 'Финансовый мониторинг',
  Rules: 'Правила',
  Violations: 'Нарушения'
};

const text = {
  Name: 'Имя',
  source: 'Источник',
  rule: 'Правило',
  gatewayMethod: 'Тип платежа',
  gatewayId: 'ID гейта',
  target: 'Цель',
  cronExpression: 'Выражение крон',
  'An error occurred during creating cron job. Please try again later.':
    'Возникла ошибка во время создании крон задания',
  'Having an account': 'Уже есть аккаунт',
  'Reset account password': 'Восстановить доступ',
  'Success update': 'Запись обновлена',
  'Do you want to remove': 'Хотите удалить запись?',
  'Register account': 'Регистрация',
  'Success registration': 'Вы успешно зарегистрированы',
  'Dashboard menu': 'Главная',
  action: 'Действие',
  reportType: 'Тип отчета',
  'Success deleted': 'Запись удалена',
  'Reset title': 'Восстановление пароля',
  'Success recovery send token': 'Инструкция по была отправлена на email',
  Filter: 'Фильтр',

  'Transactions List': 'Список транзакций',
  'Transactions Sum': 'Сумма транзакций в ПО',
  'HCB BALANCE': 'Баланс корп. счета HomeCreditBank',
  'Balance merchants': 'Балансы мерчантов',

  'Number of report lines': 'Количество строк в отчете',
  'Number of rows affected': 'Количество затронутых строк',
  'Total number of rows processed': 'Общее количество обработанных строк',

  'Transactions Confirmation': 'Справка транзакций',
  'Transactions Item': 'Информация о транзакции',
  'Create New Statement': 'Создать выписку',

  'Users List': 'Список пользователей',
  'User Item Update': 'Обновление пользователя',
  'User Item Create': 'Создание пользователя',
  'Account statements': 'Выписки по счету',
  'Download statement': 'Скачать выписку',

  'Roles List': 'Список ролей',
  'Role Item Create': 'Создание роли',
  'Role Item Update': 'Обновление роли',
  Confirm: 'Вы подтверждаете действие ?',
  fileUploaded: 'Файл успешно загружен',
  fileUploadError: 'Ошибка загрузки файла',
  'Terminals List': 'Список терминалов',
  'Terminals Item Id': 'Информация о терминале',
  'Terminals Model Create': 'Создание нового терминала',
  'Terminals Token Update': 'Обновление ключей',
  'Terminals Item Update': 'Обновление терминала',
  'Terminal Item Id': 'Информация о терминале',
  'Do you want to update token': 'Вы действительно хотите обновить ключ?',

  nonExistent: 'Отсутствует в ПО',
  missing: 'Отсутствует в отчете',
  resp_code: 'Статус(код)',
  duplicate: 'Дубликат',
  'Total Amount Report': 'Общее кол-во в отчете',
  'View results': 'Посмотреть результаты',
  new: 'Новый',
  loaded: 'Загружено',
  error: 'Ошибка',
  validation_error: 'Ошибка валидации',
  processing: 'Обработка',
  processed: 'Обработано',
  'Upload Files': 'Загрузить файл',
  'Start Date': 'Начало',
  'End Date': 'Конец',
  'Reconciliation type': 'Тип сверки',

  'Merchant List': 'Список мерчантов',
  'Merchant Item Id': 'Описание мерчанта',
  'Merchant Item Update': 'Обновление мерчанта',
  'Merchant Item Create': 'Создание мерчанта',
  'Merchant Token Update': 'Обновление ключа мерчанта',

  'Banks List': 'Список банков',
  'Banks Item Id': 'Информация о банке',
  'Bank Item Create': 'Добавление нового банка',
  'Bank Item Update': 'Обновление банка',
  'Bank Deposit Update': 'Обновление лимита',

  'Transactions Flow List': 'Список схем транзакций',
  'Transactions Flow Create': 'Создание схемы транзакции',

  'Gateway List': 'Список шлюзов',
  'Gateway Item Create': 'Создание шлюза',
  'Gateway Item': 'Описание шлюза',
  'Gateway Item Update': 'Обновление шлюза',

  'Cascading Rules List': 'Список правил',
  'Cascading Models List': 'Список моделей',
  'Cascading Model Create': 'Создание модели',

  'Codes List': 'Список кодов',
  'Codes Item Id': 'Информация о коде',
  'Code Item Update': 'Обновления кода',
  'Code Item Create': 'Создание кода',
  'Transactions Logs Item': 'Логи транзакций',
  'Reconciliation List': 'Сверка',
  miss_in_bnk: 'Отсутствует в банке',
  miss_in_pt: 'Отсутствует в paytech',
  trn_not_registered: 'Не зарегистрировано',
  miss_in_bnk2: 'Внимание!!!',
  dateStart: 'Период с',
  dateEnd: 'Период по',
  tranTypeId: 'Тип операции',
  bankId: 'Банк',
  respCode: 'Статус',
  amountFrom: 'Сумма от',
  amountTo: 'Сумма до',
  panHash: 'Хеш карты',
  panMask: 'Маска карты',
  'card first 6 number': 'Первые 6 цифр карты',
  'card last 4 number': 'Последние 4 цифры карты',
  'Type and press Enter': 'Введите и нажмите Enter',
  tranId: 'ID транзакции',
  orderId: 'ID кредита',
  clientId: 'ID клиента',
  gateway: 'Банк',
  acsStatus: 'Статус 3DS',
  approval: 'Код авторизации',
  callbackUrl: 'Call back URL',
  fee: 'Комиссия',
  getewayRefNo: 'ID банка',
  lang: 'Язык',
  pan: 'Карта',
  respCodeId: 'Код ответа',
  rrn: 'RRN',
  tranType: 'Тип операции',
  description: 'Описание',
  status: 'Статус',
  filename: 'Имя файла',
  createdAt: 'Дата создания',
  'generation duration': 'Длительность создания',
  timezone: 'Временная зона',
  comment: 'Коментарии',
  bankName: 'Банк',
  bin: 'Первые 6 цифр карты',
  countryCode: 'Код страны',
  countryName: 'Имя страны',
  fields: 'Другие поля',
  langEn: 'Английский',
  langRu: 'Русский',
  langUk: 'Украинский',
  external: 'Код',
  merchId: 'Мерчант',
  Profile: 'Профиль',
  Settings: 'Настройки',
  Logout: 'Выйти',
  Balance: 'Баланс',
  'Hold Funds': 'В обработке',
  'Available Funds': 'Доступно для выдачи',
  'Day Transactions Credit': 'Сумма выдачи за сегодня',
  'Day Transactions Debit': 'Сумма погашений за сегодня',
  'Month Transactions Credit': 'Сумма выдачи за месяц',
  'Month Transactions Debit': 'Сумма погашений за месяц',
  'Search by email': 'Поиск по email',

  reconciliationId: 'ИД сверки',
  reconciliationJobName: 'Тип Сверки',
  createOn: 'Дата',
  merchantId: 'Мерчант',
  fieldName: 'Поле сверки',
  reason: 'Статус',
  reference: 'Значение поля',
  originalField: 'Значение в ПО',
  reportField: 'Значение в отчете',
  resolved: 'Исправлено',
  reconciliationResult: 'Результат сверки',
  fixed: 'Исправить',
  reconciliation: 'Сверка',
  'Export List': 'Экспорт файлов',
  generated_reports_list: 'Список доступных файлов',
  'City24 Files List': 'Экспорт файлов city24',
  'Download File': 'Скачать файл',
  'Download Reconciliation Transactions Report': 'Скачать исходные тразакции',
  'Create Report': 'Создать отчет',
  'Create Manual Giveout Report': 'Отчет о выдаче',
  'Create Manual Giveout': 'Создать ручную выдачу',
  'Bin List': 'Список бинов',
  'Invitation link': 'Ссылка для приглашения пользователей',

  'Success tab': 'Успешные',
  Success: 'Успешнно',
  'Failed tab': 'Отклоненные',
  'Conversion tab': 'Конверсия',

  'Password was successfully changed': 'Пароль был успешно изменен',
  'Reset success send':
    'Инструкция по восстановлению пароля была отправлена на указанный email',
  'Request success send': 'Запрос успешно отправлен',
  'Error!': 'Ошибка!',
  'An error occurred during creating transaction. Please try again later.':
    'При создании ссылки произашла ошибка. Попробуйте позже.',
  'Transaction frame ready': 'Ссылка для оплаты создана',
  'Link copied': 'Скопировано',
  status_new: 'Новый',
  status_processing: 'В обработке',
  status_finished: 'Готов',
  status_failed: 'Ошибка',
  'Status done': 'Обработано',
  'Status pending': 'В обработке',
  'Status failed': 'Ошибка',
  'Status loaded': 'Загружено',
  result: 'Результат',
  'Result show': 'Результат',
  'No result': 'Не готово',
  createdBy: 'Автор',
  'Bank statements': 'Выписки',
  'Bank Operations': 'Операции',
  type: 'Тип',
  bankRef: 'Реф',
  'Debit operation': 'Выплата',
  'Credit operation': 'Пополнение',
  'Merchant Overdraft History': 'История овердрафта',
  transaction: 'Транзакция',
  Overdrafts: 'Овердрафты',
  Overdraft: 'Овердрафт',
  manualGiveoutList: 'Список ручных выдач',
  'Merchant fee rules': 'Список правил комиссий',
  'Start date': 'Дата начала',
  merchantFeePercent: 'Кешбек мерчанта в %',
  tranTypesId: 'Типы транзакций',
  strategyName: 'Тип стратегии',
  strategy: 'Тип стратегии',
  value: 'Значение',
  minValue: 'Минимальное значение комиссии',
  max: 'Максимальное значение',
  min: 'Минимальное значение',
  terminal: 'Терминал',
  priority: 'Приоритет',
  'Card Issuer Bank Name': 'Название банка эммитнта',
  'Terminal fee rules': 'Правила подсчета комиссии банка',
  'Create rule': 'Новое правило',
  'Edit rule': 'Редактирование',
  'Leave empty to create default rule':
    'Если оставить дату пустой, то будет созданно правило по умолчанию',
  'Leave empty to create wildcard rule':
    'Если оставить название банка пустым, то будет созданно правило по умолчанию',
  'Default rule': 'Нет ограничений',
  'No date restrictions': 'Нет ограничений по дате',
  'Fee Rules': 'Правила подсчета комиссии',
  NoFeeRulesDefined:
    'Для данного терминала нет правил подсчета комиссии. Создайте первое правило',
  'Successfully updated': 'Изменено успешно',
  'Successfully created': 'Создано успешно',
  'Hide deleted': 'Скрыть удаленные',
  'Show deleted': 'Показать удаленные',
  Deleted: 'Удаленно',
  'Sanctions White List': 'Белый список санкций',
  'Create sanctions white list entities': 'Добавить исключение',
  Blocking: 'Останавливает',
  'Alert only': 'Только уведомление',
  All: 'Все',
  'Financial Monitoring Rules': 'Правила финансового мониторинга',
  'Create company': 'Создание компании',
  'Update company': 'Редактирование компании',

  terminal_name: 'Имя терминала',
  'Display in sidebar balances': 'Отображать в блоке балансов',
  'Hide in sidebar balances': 'Скрыть с блока балансов',
  dashboardLabel: 'Название в блоке балансов',
  name: 'Название компании',
  iin: 'ИИН/БИН',
  bik: 'БИК',
  bankAccount: 'Номер счета компании',
  terminal_account: 'Счет терминала',
  account: 'ID транзитного счета',
  targetAccount: 'Номер счета компании',
  code: 'Код бенефициара',
  knp: 'Код назначения платежа (КНП)',
  statusName: 'Описание статуса',
  narrative: 'Комментарий',
  transport: 'Название банка транзитного счета',
  updateStatus: 'Статус обновлен',
  refresh: 'Обновить',
  'Transit Account': 'Название банка транзитного счета',
  'Create transactions': 'Создать транзакцию',
  'Delete entry': 'Запись удалена',
  'Select Company': 'Выбор компании',
  'Select Account': 'Название банка транзитного счета',
  'Create Transactions': 'Создать транзакцию',
  'Do you confirm the action?': 'Вы подтверждаете действие ?',
  'Handbook Companies': 'Справочник компаний',
  'Transit transactions': 'Транзитные транзакции',
  knpError: 'КНП состоит из 3 цифр',
  nameFormat: 'Допустимое количество символов 2-100',
  iinError: 'ИИН/БИН состоит из 12 цифр',
  invalidBankAccountFormat: 'Недопустимый формат',
  'The entered amount exceeds the available balance':
    'Введенная сумма превышает баланс выбранного транзитного счета',
  max2digits: 'Код бенефициара состоит из 2 цифр',
  'Transit account': 'Транзитный счет',
  'Update Status': 'Обновить статус',
  'Send to Bank': 'Отправить в банк',
  'Violation List': 'Список нарушений',
  'Transaction prefix': 'Префикс транзакции',
  startDate: 'Дата и время начала периода',
  endDate: 'Дата и время конца периода',
  infoTranid:
    'Если в поле "Префикс транзакции" выбран вариант,  то при вводе "ID транзакции" следует добавить номер транзакции без префикса "kassa24" или "qiwi". Если префикс транзакции не указан, поиск будет осуществляться по полному совпадению с полным значением "ID транзакции".',
  'limit exceeded': 'Превышен лимит на создание отчета'
};

const fields = {
  gatewayId: 'ID гейта',
  tranTypesId: 'Типы транзакций',
  orderBy: 'Сортировать по',
  sortDescending: 'По убыванию',
  sortAscending: 'По возрастанию',
  uuid: 'ID',
  name: 'Название',
  createOn: 'Создано',
  merchant: 'Мерчант',
  tranId: 'Id транзакции',
  tranType: 'Тип операции',
  pan: 'Номер карты',
  amount: 'Сумма',
  fee: 'Комиссия',
  gateway: 'Экваир',
  respCode: 'Код ответа',
  lang: 'Язык',
  editOn: 'Обновлено',
  firstName: 'Имя',
  lastName: 'Фамилия',
  phone: 'Телефон',
  loginTries: 'Попыток авторизации',
  lastLogin: 'Дата авторизации',
  linkToken: 'Ключ',
  role: 'Роль',
  'email table': 'Email',
  field_email: 'Email',
  'role name field': 'Роль',
  'permissions field': 'Разрешения',
  'name bank field': 'Название банка',
  depositLimit: 'Лимит депозита',
  keyToken: 'Ключи',
  flowName: 'Название схемы',
  endpoint: 'Точка входа',
  env: 'Окружение',
  bank: 'Банк',
  'name gateway field': 'Название шлюза',
  'Select value': 'Выбрать значение',
  type_operation: 'Тип операции',
  notificationChannel: 'Канал нотификации',
  'Set deposit limit': 'Установить',
  'Increase deposit limit': 'Увеличить',
  'Decrease deposit limit': 'Уменьшить',
  'Comment show': 'Комментарий',
  Loading: 'Загрузка',
  'Upload file': 'Загрузить файл',
  rows_per_page: 'Количество записей на страницу',
  from: 'из',
  to: 'по',
  count: 'количество',
  greater_than: 'больше чем',
  page: 'страница',
  'Upload manual giveout file': 'Импорт с файла',
  'File upload': 'Файл успешно загружен',
  'Upload Files': 'Загрузка файла',
  'Reconciliation type': 'Тип сверки',
  cityRespCode: 'City24 статус',
  company_email: 'Email компании',
  company_id: 'ID компании',
  TranId: 'ID транзакции',
  Description: 'Описание',
  'Agreement number': 'Номер договора',
  clientName: 'ФИО клиента',
  phoneNumber: 'Телефон клиента',
  Fee: 'Комиссия',
  minFee: 'Минимальная комиссия',
  Amount: 'Сумма',
  'Payment link': 'Ссылка для оплаты',
  docRef: 'Номер документ',
  'document reference': 'Номер документ',
  source: 'Источник',
  extra: 'Дополнительно',
  'first name': 'Имя',
  'last name': 'Фамилия',
  notes: 'Заметки',
  Excluded: 'Исключенно',
  Critical: 'Обязательно',
  frequency: 'Период',
  startedAt: 'Начало',
  finishedAt: 'Конец',
  status: 'Статус',
  'Server maintenance schedule': 'Расписание серверных работ',
  'Create schedule': 'Создать расписание',
  'Edit schedule': 'Редактировать расписание',
  downloadManualGiveoutTemplate: 'Скачать шаблон ручной выдачи',
  createManualGiveout: 'Создать ручную выдачу',
  downloadImportBinTemplate: 'Скачать шаблон',
  'Merchant Account Item': 'Счет партнера',
  id: 'Идентификатор',
  Name: 'Имя',
  'Overdarft Limit': 'Лимит овердрафта',
  'Account operations': 'Операции по счету',
  NoOperations: 'Нет операций, зарегистрированных на счете',
  ready: 'Готов',
  balance: 'Баланс',
  'Merchant Account': 'Счет партнера',
  gatewayRefNo: 'Ref банка',
  holder: 'Владелец карты',
  customerId: 'Id клиента',
  ip: 'IP адрес',
  cardHash: 'Хеш карты',
  country: 'Страна',
  'Make Main Account': 'Сделать основным счетом',
  'Main Account': 'Главный счет',
  'Edit account': 'Редактировать счет',
  Update: 'Обновить',
  'Merchant Account Cron Jobs': 'Крон задачи счетов партнера',
  accounts: 'Счета',
  enabled: 'Включен',
  cronExpression: 'Выражение крон',
  'Create cron job': 'Создать задачу крон',
  balanceTimeAdjustment: 'Сдвиг во времени',
  city: 'Город',
  email: 'Email',
  panHash: 'Хеш карты',
  clientIp: 'ip клиента',
  customerEmail: 'Email клиента',
  cardHolder: 'Card holder',
  respMessage: 'Описание статуса',
  timezone: 'Часовой пояс',
  region: 'Регион',
  country_code: 'Код страны',
  country_code3: 'Код страны',
  knownHolders: 'Держатели карты',
  documentRef: 'Номер документа',
  filterMerchants: 'Мерчанты',
  merchantName: 'Имя мерчанта',
  actions: 'Действия',
  manual_giveout_user_created_email: 'Пользователь, создавший ручную выдачу',
  user: 'Пользователь',
  'Bank Deposit History': 'История банковских депозитов',
  filterTransactionTypes: 'Типы транзакций',
  critical: 'Важность',
  Enabled: 'Включено',
  Accounts: 'Счета',
  Invoice: 'Чек',
  'Cron Jobs': 'Крон задачи',
  'Merchant Accounts': 'Счета партнера',
  Balance: 'Баланс',
  overdraftLimit: 'Лимит овердрафта',
  default: 'Основной',
  'Create account': 'Создать счет',
  Disabled: 'Отключено',
  ruleType: 'Тип правила',
  limit: 'Лимит',
  startDate: 'Дата отсчета',
  transactionTypes: 'Типы транзакций',
  merchants: 'Мерчанты',
  'Merchants filter enabled': 'Фильтр по мерчантам включен',
  'Merchants filter disabled': 'Фильтр по мерчантам выключен',
  'Transaction types filter enabled': 'Фильтр по типам транзакций включен',
  'Transaction types filter disabled': 'Фильтр по типам транзакций выключен',
  reconciliationName: 'Название',
  field: 'Поле',
  strategyName: 'Тип стратегии',
  amountField: 'Сумма(номер поля)',
  amountScale: 'Шаг суммы',
  apiLogin: 'Апи логин',
  apiPassword: 'Апи пароль',
  bottomMargin: 'Отступ снизу(строка)',
  checkAllTabs: 'Проверять все страницы',
  dateField: 'Дата(номер поля)',
  dateFormat: 'Формат даты',
  headerMargin: 'Отступ сверху(строка)',
  referenceField: 'Id платежа(номер поля)',
  sheetName: 'Имя страницы',
  tranTypes: 'Типы транзакций',
  usingReportInterval: 'Интервал дат из файла',
  minDateInterval: 'Минимальный интервал даты(сек)',
  maxDateInterval: 'Максимальный интервал даты(сек)',
  transactionRegEx: 'RegEx транзакции',
  transactionPostfix: 'Постфикс транзакции',
  transactionPrefix: 'Префикс транзакции',
  true: 'Да',
  false: 'Нет',
  parsingSettings: 'Настройки',
  'Reconciliation settings': 'Настройки',
  'Create reconciliation settings': 'Создать настройки',
  'Edit reconciliation settings': 'Редактировать настройки',
  'Main settings': 'Основные',
  'Parsing settings': 'Парсинг',
  'Reconciliation settings Item': 'Настройки',
  'Transaction confirmations': 'Справки по транзакциям',
  date: 'Дата',
  confirmation: 'Справка',
  loading: 'Загрузка'
};

const transaction_confirmation = {
  status: {
    new: 'В очереди',
    generating: 'Генерируется',
    generated: 'Сгенерирован, ожидает архивации',
    compressing: 'Архивируется',
    ready: 'Готов',
    empty: 'Транзакции не найдены'
  },
  create_from_file: 'Создать из csv файла',
  file_example: 'Пример содержимого файла'
};

const report = {
  status: {
    1: 'В очереди',
    2: 'Создается',
    3: 'Готов',
    4: 'Ошибка'
  }
};

const server = {
  'Token error': 'Неверный ключ',
  '404 error title': 'Страница не найдена',
  '404 error description':
    'Страница не найдена. Скорее всего она была удалено или перемещена :('
};

const validationForm = {
  required: 'Поле обязательно для заполнения',
  email: 'Поле должно быть почтовым ящиком',
  'Passwords must match': 'Пароли не совпадают',
  'Error password format':
    'В пароле должно быть минимум 8 символом. Используйте прописные и заглавные буквы,а также цифры и спец. символы',
  'Error phone format': 'Телефон должен быть в формате 380XXXXXXXXX'
};

export const ru = {
  'Forgot password': 'Забыли пароль?',
  'Create new account': 'Создать аккаунт',
  'Login text': 'Авторизация',
  ...buttons,
  ...fields,
  ...text,
  ...validationForm,
  ...menu,
  ...server,
  report: report,
  transaction_confirmation: transaction_confirmation
};
